const addClassOnScroll = (selector, classNames) => selector.classList.add(classNames)
const removeClassOnScroll = (selector, classNames) => selector.classList.remove(classNames)

const initialNavbarPosition = 545

document.addEventListener('turbolinks:load', () => {
  const navbar = document.querySelector('nav')
  const navbarBrand = document.querySelector('.navbar-brand')
  const navbarMenu = document.querySelector('#navBarMenu')

  navbarMenu.addEventListener('click', (event) => {
    navbarMenu.classList.toggle('show')
  });

  window.addEventListener('scroll', () => {
    const scrollPosition = window.scrollY

    if (scrollPosition >= initialNavbarPosition) {
      removeClassOnScroll(navbar, 'navbar-expand-lg')
      removeClassOnScroll(navbarBrand, 'd-none')
    } else {
      addClassOnScroll(navbar, 'navbar-expand-lg')
      addClassOnScroll(navbarBrand, 'd-none')
    }
  })
})
