const Lightpick = require('lightpick')

const createDatepicker = (args = {}) => {
  const getElement = (selector, fallback = null) =>
    document.querySelector(selector) || fallback

  const elements = {
    dateRange: getElement(`#${args.date_range || 'date_range'}`),
    checkIn: getElement('input[name="check_in"]'),
    checkOut: getElement('input[name="check_out"]'),
    closeSeasonDates: document.getElementById('search-date')?.dataset.closeSeasonDates,
  }

  const parseCloseSeasonDates = () =>
    (elements.closeSeasonDates ? JSON.parse(elements.closeSeasonDates) : undefined)

  const parseDates = (start, end, value) => {
    if (start && end) return [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]

    return value.split('-').map(date =>
      new Date(date).toISOString().split('T')[0])
  }

  const updateHiddenFields = () => {
    const [start, end] = parseDates(light.getStartDate(), light.getEndDate(), elements.dateRange.value)
    elements.checkIn.value = start || ''
    elements.checkOut.value = end || ''
  }

  const clearHiddenFields = () => {
    elements.checkIn.value = ''
    elements.checkOut.value = ''
    light.setDateRange(null, null)
  }

  const handleOnClose = () => {
    if (!light.getStartDate() || !light.getEndDate())
      clearHiddenFields()
  }

  const options = {
    disableDates: parseCloseSeasonDates(),
    field: elements.dateRange,
    firstDay: 7,
    format: 'MM/DD/YYYY',
    minDate: Date.now(),
    minDays: 2,
    numberOfMonths: 2,
    singleDate: false,
    onClose: handleOnClose,
    ...args,
  }

  const light = new Lightpick(options)

  return { ...elements, updateHiddenFields }
}

const attachFormSubmission = (datepickers, datepicker) => {
  datepickers.forEach(picker => {
    const formId = picker.getAttribute('data-bs-target')
    const form = document.getElementById(formId)

    if (form)
      form.onsubmit = () => {
        datepicker.updateHiddenFields()
        return true
      }
  })
}

document.addEventListener('turbolinks:load', () => {
  const datepickers = Array.from(document.getElementsByClassName('datepicker'))

  if (datepickers.length > 0) {
    const datepicker = createDatepicker()
    attachFormSubmission(datepickers, datepicker)
  }
})
